import React from 'react';

import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { Content } from '../components/content';

export default function() {
  const title = 'Blanqueamiento dental';

  return (
    <Layout>
      <SEO title={title} />
      <h1>{title}</h1>
      <Content>
        <p>
          Múltiples son las causas que originan la pérdida de varios puntos en la tonalidad y coloración de las piezas
          dentales. Entre otras se encuentran la ingesta de: café, mate, te, bebidas colas consumidas en exceso, el
          tabaco, sustancias químicas utilizadas en colutorios o antisépticos, medicamentos, la oxidación de antiguas
          amalgamas y tratamientos de conducto.
        </p>
        <p>
          El blanqueamiento dental es un tratamiento que tiene como finalidad devolverle a los dientes su tono original
          a través de un proceso de remoción de las sustancias depositadas sobre el esmalte dentario.
        </p>
        p Dicho procedimiento es rápido e indoloro y aplicado en forma correcta no produce desgaste de las piezas
        dentales.
        <p>
          Cada tratamiento de blanqueamiento que aplicamos en nuestros centros es de acuerdo a un análisis personalizado
          en cada paciente con el fin de lograr el resultado más satisfactorio en el procedimiento. Nuestros
          tratamientos recuperan la mejor estética para el paciente. Resultan eficaces, indoloros y sin efectos
          secundarios. No son agresivos y en ningún caso alteran la estructura de las piezas dentales.
        </p>
        <h2>Preguntas frecuentes</h2>
        <h3>¿En cuánto tiempo se realiza un blanqueamiento dental?</h3>
        <p>
          El blanqueamiento es una técnica de resolución inmediata que puede ser realizado en una sola sesión en el
          consultorio dental. Por lo general no demora más de una hora.
        </p>
        <h3>Es doloroso el tratamiento de blanqueamiento dental?</h3>
        <p>No, en absoluto. Es un tratamiento totalmente indoloro y muy poco invasivo. No requiere anestesia.</p>
        <h3>¿Cuales son los resultados del blanqueamiento dental?</h3>
        <p>
          De acuerdo al método que se utilice los pacientes pueden alcanzar resultados que oscilan entre 2 y 3 tonos más
          blancos, finalizado el procedimiento.
        </p>
        <h3>¿Cuánto tiempo dura un tratamiento de blanqueamiento dental?</h3>
        <p>
          El blanqueamiento brinda resultados prolongados, el mismo se modificará de acuerdo a ciertas variables. En
          primera instancia es necesario mantener una correcta higiene oral a diario. También se recomienda limitar la
          ingesta o utilización de los alimentos o productos que originaron el oscurecimiento. No obstante, si esto
          ocurriera se recomienda un retoque ocasional con un producto de uso hogareño, de persistir la coloración se
          recomienda concurrir nuevamente al profesional.
        </p>
      </Content>
    </Layout>
  );
}
